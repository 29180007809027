import Button, { ButtonSize } from 'app/components/Button'
import { CurrencyLogo } from 'app/components/CurrencyLogo'
import ExternalLink from 'app/components/ExternalLink'
import { GET } from 'app/config/sculptor/stake'
import { GET_TOKEN } from 'app/config/tokens/index'
import { useActiveWeb3React } from 'app/services/web3'
import React from 'react'

interface VestSculptBottonProps {
  size?: ButtonSize
}

const BuySculptBotton = ({ size }: VestSculptBottonProps) => {
  const { chainId } = useActiveWeb3React()
  const token = GET_TOKEN(chainId).SCULPT
  const stakeAddresses = GET(chainId)
  const { exchangeName, swapUrl } = stakeAddresses

  return (
    <Button className="m-2" size={size}>
      <ExternalLink href={`${swapUrl}&outputCurrency=${token.address}`} className="flex flex-row" color="white">
        View{' '}
        <span className="inline">
          <CurrencyLogo currency={token} size={16} style={{ marginLeft: 4, marginRight: 4, marginTop: 2 }} />
        </span>{' '}
        On {exchangeName}
      </ExternalLink>
    </Button>
  )
}

export default BuySculptBotton
