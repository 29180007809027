import { Token } from '@sushiswap/core-sdk'
import Button, { ButtonSize } from 'app/components/Button'
import { GET_TOKEN } from 'app/config/tokens'
import useStakeToken from 'app/hooks/sculptor/useStakeToken'
import { useActiveWeb3React } from 'app/services/web3'
import { BigNumber as BigNumberJS } from 'bignumber.js'
import { currencyAmountToBigNumberJS, format } from 'lib/formatter'
import React, { useEffect, useMemo, useState } from 'react'

interface VestSculptBottonProps {
  size?: ButtonSize
}

const VestSculptBotton = ({ size }: VestSculptBottonProps) => {
  const { chainId } = useActiveWeb3React()
  const token = GET_TOKEN(chainId).SCULPT

  const { claim, isClaiming, useAllClaimableIncentiveRewards } = useStakeToken()

  const allClaimableIncentiveRewards = useAllClaimableIncentiveRewards()
  // let allClaimableIncentiveRewardsAmount = new BigNumberJS(0)
  // let allClaimableIncentiveRewardsAmountDollar = new BigNumberJS(0)
  // const allClaimableIncentiveRewardAddresses: Token[] = []
  // for (let i = 0; i < allClaimableIncentiveRewards.length; i++) {
  //   const amount = currencyAmountToBigNumberJS(allClaimableIncentiveRewards[i].amount)
  //   allClaimableIncentiveRewardsAmount = allClaimableIncentiveRewardsAmount.plus(amount)
  //   allClaimableIncentiveRewardsAmountDollar = allClaimableIncentiveRewardsAmount
  //     .plus(amount)
  //     .times(allClaimableIncentiveRewards[i].tokenPrice)
  //   allClaimableIncentiveRewardAddresses.push(allClaimableIncentiveRewards[i].token)
  // }

  const [allClaimableIncentiveRewardsAmount, allClaimableIncentiveRewardAddresses] = useMemo(() => {
    return allClaimableIncentiveRewards.reduce(
      (acc, d) => {
        const amount = currencyAmountToBigNumberJS(d.amount)
        acc[0] = acc[0].plus(amount)
        acc[1].push(d.token)
        return acc
      },
      [new BigNumberJS(0), []] as [BigNumberJS, Token[]]
    )
  }, [allClaimableIncentiveRewards])

  const [buttonText, setButtonText] = useState('...')
  useEffect(() => {
    if (isClaiming) {
      setButtonText('Vesting...')
    } else {
      setButtonText(`Vest ${format(allClaimableIncentiveRewardsAmount)} ${token.symbol}`)
    }
  }, [isClaiming, allClaimableIncentiveRewardsAmount, token.symbol])

  const handleVest = async () => {
    await claim(allClaimableIncentiveRewardAddresses)
  }

  if (allClaimableIncentiveRewardsAmount.eq(0)) {
    return <></>
  }

  return (
    <Button className="m-2" size={size} disabled={isClaiming} onClick={handleVest}>
      {buttonText}
    </Button>
  )
}

export default VestSculptBotton
